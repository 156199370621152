import styled from 'styled-components';

import logo from 'assets/icons/mdhealth_logo.svg';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background: no-repeat center;
  background-size: 150px;
  background-image: url(${logo});
  animation: entering 0.5s;
  h1 {
    padding-top: 4em;
    color: var(--blue);
  }
`;
