import * as Types from './types';

interface DispatchParameters {
  type: string;
  payload: any;
}

const INITIAL_STATE = {
  id: 0,
  name: '',
  email: '',
};

export default (
  state = INITIAL_STATE,
  { type, payload }: DispatchParameters,
) => {
  switch (type) {
    case Types.UPDATE_EMAIL:
      return { ...state, email: payload };
    default:
      return state;
  }
};
