import { createGlobalStyle } from 'styled-components';
import 'antd/dist/antd.css';

export default createGlobalStyle`

/* Reset */
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{border:0;font-size:100%;font:inherit;vertical-align:baseline;margin:0;padding:0}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:none}table{border-collapse:collapse;border-spacing:0}


  :root{
  --blue: #095070;
  --secondary-blue: #004C6E;
  --gray: #6E6E6E;
  --gray300: #939393;
  --gray38: #383838;
  --gray85: #858585;

  --cyanea: #EAF5FC;
  --blue00: #008CE9;
  --gray70: #707070;
  }

  html{
    min-height: 100%;
    scroll-behavior: smooth;
    scroll-margin-top: 15em;
  }

 body{
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    
    min-height: 100%;

    display: flex;
    flex-direction: column;
    position: relative;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  @keyframes entering{
    from{
      opacity:0
      }
    to{
        opacity:1
    }
  }

  @keyframes slide_down {
    0% {
      display: none;
      max-height: 0;
    }
    10% {
      display: block;
    }
    100% {
      max-height: 9999px;
    }
  }

  /* Ant design items */
  .ant-select-dropdown, .ant-select-dropdown-hidden {
        border-radius: 28px;
        box-shadow: 0px 8px 16px rgba(0,0,0, 0.12) !important;
        border: 1px solid var(--blue) !important;
  }

  .ant-select-item-option-selected{
    color: var(--blue) !important;
    background-color: #fff !important;
    margin: 0 .5px !important;
  }

  .ant-select-item-option-active{
    background-color: transparent !important;
  }

  .rc-virtual-list-scrollbar-thumb{
    background: rgba(9, 80, 112, 0.9) none repeat scroll 0% 0% !important;
  }



  
`;
