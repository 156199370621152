import React, { lazy, Suspense } from 'react';

import { BrowserRouter, Route } from 'react-router-dom';

import { LoadingScreen } from 'shared/LoadingScreen/LoadingScreen';

const Home = lazy(() =>
  import('pages/Home/Home').then(({ Home }) => ({ default: Home })),
);

const Register = lazy(() =>
  import('pages/Register/Register').then(({ Register }) => ({
    default: Register,
  })),
);

const Login = lazy(() =>
  import('pages/Login/Login').then(({ Login }) => ({ default: Login })),
);

const Jobs = lazy(() =>
  import('pages/Jobs/Jobs').then(({ Jobs }) => ({ default: Jobs })),
);

const AboutUs = lazy(() =>
  import('pages/AboutUs/AboutUs').then(({ AboutUs }) => ({
    default: AboutUs,
  })),
);

const Webinars = lazy(() =>
  import('pages/Webinars/Webinars').then(({ Webinars }) => ({
    default: Webinars,
  })),
);

const Events = lazy(() =>
  import('pages/Events/Events').then(({ Events }) => ({
    default: Events,
  })),
);

const Courses = lazy(() =>
  import('pages/Courses/Courses').then(({ Courses }) => ({
    default: Courses,
  })),
);

const Videos = lazy(() =>
  import('pages/Videos/Videos').then(({ Videos }) => ({
    default: Videos,
  })),
);

const IndependentMedicEducation = lazy(() =>
  import('pages/IndependentMedicEducation/IndependentMedicEducation').then(
    ({ IndependentMedicEducation }) => ({
      default: IndependentMedicEducation,
    }),
  ),
);

const PaymentPage = lazy(() =>
  import('pages/PaymentPage/PaymentPage').then(({ PaymentPage }) => ({
    default: PaymentPage,
  })),
);

const NeurologyCategoryPage = lazy(() =>
  import('pages/NeurologyCategoryPage/NeurologyCategoryPage').then(
    ({ NeurologyCategoryPage }) => ({
      default: NeurologyCategoryPage,
    }),
  ),
);

const OncologyCategoryPage = lazy(() =>
  import('pages/OncologyCategoryPage/OncologyCategoryPage').then(
    ({ OncologyCategoryPage }) => ({
      default: OncologyCategoryPage,
    }),
  ),
);

const CardiologyCategoryPage = lazy(() =>
  import('pages/CardiologyCategoryPage/CardiologyCategoryPage').then(
    ({ CardiologyCategoryPage }) => ({
      default: CardiologyCategoryPage,
    }),
  ),
);

const UrologyCategoryPage = lazy(() =>
  import('pages/UrologyCategoryPage/UrologyCategoryPage').then(
    ({ UrologyCategoryPage }) => ({
      default: UrologyCategoryPage,
    }),
  ),
);

const UserProfile = lazy(() =>
  import('pages/UserProfile/UserProfile').then(({ UserProfile }) => ({
    default: UserProfile,
  })),
);

const New = lazy(() =>
  import('pages/New/New').then(({ New }) => ({
    default: New,
  })),
);

const CourseDetails = lazy(() =>
  import('pages/CourseDetails/CourseDetails').then(({ CourseDetails }) => ({
    default: CourseDetails,
  })),
);

const ModuleDetails = lazy(() =>
  import('pages/ModuleDetails/ModuleDetails').then(({ ModuleDetails }) => ({
    default: ModuleDetails,
  })),
);

const LivePage = lazy(() =>
  import('pages/LivePage/LivePage').then(({ LivePage }) => ({
    default: LivePage,
  })),
);

const VideoPage = lazy(() =>
  import('pages/VideoPage/VideoPage').then(({ VideoPage }) => ({
    default: VideoPage,
  })),
);

const renderLoader = () => <LoadingScreen />;

const Routes = () => (
  <BrowserRouter>
    <Suspense fallback={renderLoader()}>
      <Route component={Home} path="/" exact />

      <Route component={Login} path="/login" exact />
      <Route component={Register} path="/cadastro" exact />
      <Route component={Jobs} path="/vagas" exact />
      <Route component={AboutUs} path="/quem-somos" exact />
      <Route component={Webinars} path="/webinars" exact />
      <Route component={Events} path="/eventos" exact />
      <Route component={Courses} path="/cursos" exact />
      <Route component={Videos} path="/videos" exact />
      <Route
        component={IndependentMedicEducation}
        path="/educacao-medica-independente"
        exact
      />
      <Route component={PaymentPage} path="/pagamento/:curso" exact />

      <Route
        component={NeurologyCategoryPage}
        path="/categoria-neurologia"
        exact
      />

      <Route
        component={OncologyCategoryPage}
        path="/categoria-oncologia"
        exact
      />

      <Route
        component={CardiologyCategoryPage}
        path="/categoria-cardiologia"
        exact
      />

      <Route component={UrologyCategoryPage} path="/categoria-urologia" exact />

      <Route component={UserProfile} path="/usuario" exact />

      <Route component={New} path="/noticia/:category/:id" exact />

      <Route component={CourseDetails} path="/detalhes-do-curso" exact />

      <Route component={ModuleDetails} path="/modulo" exact />

      <Route component={LivePage} path="/ao-vivo" exact />

      <Route component={VideoPage} path="/video" exact />
    </Suspense>
  </BrowserRouter>
);

export default Routes;
