import { Container } from './_loadingScreen';

export const LoadingScreen = () => {
  return (
    <>
      <Container>
        <h1>Carregando ...</h1>
      </Container>
    </>
  );
};
